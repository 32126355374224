import { CloseButton, Modal } from "react-bootstrap";
import { ModalPropsDefault, RitualParametersFormValue } from "../../types";
import RituelParametersForm from "../RituelParametersForm";
import { useNavigate } from "react-router-dom";
import Routes from "../../routes";

type RituelParametersModalProps = {
  setParameters?: (param: RitualParametersFormValue) => void;
};

function RituelParametersModal({ show, setParameters }: ModalPropsDefault & RituelParametersModalProps) {
  const navigate = useNavigate();

  return (
    <Modal show={show} size="lg" onHide={() => navigate(Routes.HOME)} backdrop="static">
      <Modal.Header>
        <h4>Veuillez renseigner les informations suivantes :</h4>
        <CloseButton variant="white" onClick={() => navigate(Routes.HOME)} />
      </Modal.Header>
      <Modal.Body>
        <RituelParametersForm setParameters={setParameters} />
      </Modal.Body>
    </Modal>
  );
}

export default RituelParametersModal;
