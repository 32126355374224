import "./Dice.css";

type DiceProps = {
    rotateNum?: number;
};

function Dice({ rotateNum = 0 }: DiceProps) {
    const rotateArray = function (nums: string[], k: number): string[] {
        for (let i = 0; i < k; i++) {
            nums.unshift(nums.pop() as string);
        }

        return nums;
    };

    let className = ["face face-avant", "face face-bas", "face face-droite", "face face-gauche", "face face-haut", "face face-arriere"];
    className = rotateArray(className, rotateNum);

    return (
        <div className="cube">
            <div className={className[0]}>
                <div>
                    <span></span>
                </div>
            </div>
            <div className={className[1]}>
                <div>
                    <span></span>
                    <span></span>
                </div>
                <div>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className={className[2]}>
                <div>
                    <span></span>
                    <span></span>
                </div>
                <div>
                    <span></span>
                </div>
                <div>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className={className[3]}>
                <div>
                    <span></span>
                    <span className="fake"></span>
                </div>
                <div>
                    <span className="fake"></span>
                    <span></span>
                </div>
            </div>
            <div className={className[4]}>
                <div>
                    <span></span>
                    <span className="fake"></span>
                </div>
                <div>
                    <span></span>
                </div>
                <div>
                    <span className="fake"></span>
                    <span></span>
                </div>
            </div>
            <div className={className[5]}>
                <div>
                    <span></span>
                    <span></span>
                </div>
                <div>
                    <span></span>
                    <span></span>
                </div>
                <div>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    );
}

export default Dice;
