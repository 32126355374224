import { IconDefinition, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

type FormButtonLoadingProps = {
  isPending: boolean;
  label: string;
  showLoading?: boolean;
  icone?: IconDefinition;
};

function FormButtonLoading({ isPending, label, showLoading = true, icone = undefined, ...props }: FormButtonLoadingProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button {...{ ...props, disabled: isPending || props.disabled }}>
      {icone && <FontAwesomeIcon icon={icone} />} {label}
      {isPending && showLoading && <FontAwesomeIcon className="ms-1" icon={faSpinner} spin />}
    </button>
  );
}

export default FormButtonLoading;
