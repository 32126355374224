import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@tanstack/react-query";
import { api, ApiError, useDocumentTitle } from "../hooks";
import { useForm } from "react-hook-form";
import { FormButtonLoading } from "../components";
import { MutationData } from "../types";
import { Alert } from "react-bootstrap";

type FormValues = {
  email: string;
};

const defaultValues: FormValues = {
  email: "",
};

function PasswordResetRequest() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  useDocumentTitle();

  const passwordResetRequest = useMutation<MutationData, ApiError, FormValues>({
    mutationFn: (data) => {
      return api.user.passwordResetRequest(data);
    },
    onSuccess: () => {
      reset();
    },
  });

  const onSubmit = (data: FormValues) => {
    passwordResetRequest.mutate(data);
  };

  return (
    <div className="form-template mx-auto">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="label-form">
          <h1 className="h3 text-center">Réinitialiser son mot de passe</h1>
        </div>
        <div className="alert-form">
          {passwordResetRequest.isError && <Alert variant={passwordResetRequest.error.variant}>{passwordResetRequest.error.message}</Alert>}
          {passwordResetRequest.isSuccess && <Alert variant="success">{passwordResetRequest.data.message}</Alert>}
        </div>

        <div className="body-form">
          <p className="mb-3">Veuillez renseigner votre email</p>

          <div className="mb-3 input-group">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
            <div className="form-floating">
              <input type="text" {...register("email", { required: true })} id="inputEmail" className={"form-control" + (errors.email ? " is-invalid" : "")} placeholder="Email" required autoFocus />
              <label htmlFor="inputEmail" className="form-label">
                Email
              </label>
            </div>
            {errors.email && <div className="invalid-feedback d-block">Vous devez saisir votre email</div>}
          </div>

          <div className="btn-form">
            <FormButtonLoading isPending={passwordResetRequest.isPending} label="Envoyer" className="btn btn-outline-dark" />
          </div>
        </div>
      </form>
    </div>
  );
}

export default PasswordResetRequest;
