import { ProfilAPI, ProgrammationAPI, RituelAPI, UserAPI } from "./apis";

const api = {
  profil: new ProfilAPI(),
  programmation: new ProgrammationAPI(),
  rituel: new RituelAPI(),
  user: new UserAPI(),
};

export default api;
