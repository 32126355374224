import { useForm } from "react-hook-form";
import { FormButtonLoading } from "../components";
import { api, ApiError } from "../hooks";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../contexts/auth";
import { MutationData } from "../types";
import { Alert } from "react-bootstrap";

type FormValues = {
  codeVip: string;
};

const defaultValues: FormValues = {
  codeVip: "",
};

function GrantedVipForm() {
  const { user } = useAuth();
  const { register, handleSubmit, reset } = useForm({
    defaultValues: defaultValues,
  });

  const grantedVip = useMutation<MutationData, ApiError, FormValues>({
    mutationFn: (data) => {
      return api.profil.grantedVip(data);
    },
    onSuccess: () => {
      reset();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    },
  });

  const onSubmit = (data: FormValues) => {
    grantedVip.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {grantedVip.isError && <Alert variant={grantedVip.error.variant}>{grantedVip.error.message}</Alert>}
      {grantedVip.isSuccess && <Alert variant="success">{grantedVip.data.message}</Alert>}
      <div className="input-group mb-3">
        <input type="text" {...register("codeVip")} aria-describedby="becomeVip" className="form-control" placeholder="Code VIP" required />
        <FormButtonLoading
          className={"btn btn-outline-ternary w-50" + (user?.isVip ? " bg-secondary disabled" : "")}
          type="submit"
          label={user?.isVip ? "Vous êtes déjà identifié comme VIP" : "Envoyer"}
          id="becomeVip"
          isPending={grantedVip.isPending}
        />
      </div>
    </form>
  );
}

export default GrantedVipForm;
