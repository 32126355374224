/**
 * All available routes for the app
 */

const Routes = {
  HOME: "/",

  RITUEL: "/rituel",
  HELP_RITUEL: "/help/rituel",

  LOGIN: "/login",
  REGISTER: "/register",
  RESEND_VERIFY_EMAIL: "/resend-verify-email",
  PASSWORD_RESET_REQUEST: "/password-reset-request",
  PASSWORD_RESET: "/password-reset/:token",
  UNSUBSCRIBE_EMAILS: "/unsubscribe-emails",
  PROFIL: "/profil",
  PROFIL_PARAMETERS: "/profil/parameters",

  LEGAL_NOTICES: "/mentions-legales",
  PRIVACY: "/privacy",
  CGU: "/cgu",
  CONTACT: "/contact",
  CREDITS: "/credits",

  // Others url
  CONTRIBUTE: process.env.REACT_APP_BANQUE_URL + "/add/:type",
} as const;

export default Routes;
