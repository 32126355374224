import axios from "axios";
import { getPostPutAxiosInstance, handleError } from "./utils";

class ProfilAPI {
  async changePassword(data: any) {
    try {
      const response = await getPostPutAxiosInstance().put("/profil/change-password", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async grantedAdherent(data: any) {
    try {
      const response = await getPostPutAxiosInstance().put("/profil/become-adherent", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async grantedVip(data: any) {
    try {
      const response = await getPostPutAxiosInstance().put("/profil/become-vip", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async deleteAccount(data: any) {
    try {
      const response = await getPostPutAxiosInstance().put("/profil/delete-account", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async updateRituelParameters(data: any) {
    try {
      const response = await getPostPutAxiosInstance().put("/profil/update-rituel-parameters", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }
}

export default ProfilAPI;
