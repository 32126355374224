import { useForm } from "react-hook-form";
import { FormButtonLoading } from "../components";
import { api, ApiError } from "../hooks";
import { useMutation } from "@tanstack/react-query";
import { DefaultErrorsType, MutationData } from "../types";
import { Alert } from "react-bootstrap";

type FormValues = {
  oldPassword: string;
  newPasswordFirst: string;
  newPasswordSecond: string;
};

const defaultValues: FormValues = {
  oldPassword: "",
  newPasswordFirst: "",
  newPasswordSecond: "",
};

const defaultErrors: DefaultErrorsType<FormValues> = {
  oldPassword: {
    required: "Veuillez saisir votre mot de passe actuel.",
  },
  newPasswordFirst: {
    required: "Veuillez saisir votre nouveau mot de passe.",
    minLength: { value: 6, message: "Le mot de passe doit contenir au moins 6 caractères." },
  },
  newPasswordSecond: {
    required: "Veuillez confirmer votre nouveau mot de passe.",
    validate: (value, formValues) => value === formValues.newPasswordFirst || "Les deux mots de passe doivent être identiques.",
  },
};

function ChangePasswordForm() {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
  });

  const changePassword = useMutation<MutationData, ApiError<FormValues>, FormValues>({
    mutationFn: (data) => {
      return api.profil.changePassword(data);
    },
    onSuccess: () => {
      reset();
    },
    onError: (data) => {
      data.formErrors.forEach(({ name, message }) => {
        setError(name, { message: message });
      });
    },
  });

  const onSubmit = (data: FormValues) => {
    changePassword.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {changePassword.isError && changePassword.error.formErrors.length === 0 && <Alert variant={changePassword.error.variant}>{changePassword.error.message}</Alert>}
      {changePassword.isSuccess && <Alert variant="success">{changePassword.data.message}</Alert>}

      {errors.root && <Alert variant="danger">{errors.root.message}</Alert>}

      <div className="mb-3">
        <label htmlFor="old_password" className="form-label">
          Mot de passe actuel
        </label>
        <input type="password" {...register("oldPassword", defaultErrors.oldPassword)} id="old_password" className={"form-control" + (errors.oldPassword ? " is-invalid" : "")} required />
        {errors.oldPassword && <div className="invalid-feedback d-block">{errors.oldPassword.message}</div>}
      </div>
      <div className="mb-3">
        <label htmlFor="new_password_first" className="form-label">
          Nouveau mot de passe
        </label>
        <input
          type="password"
          {...register("newPasswordFirst", defaultErrors.newPasswordFirst)}
          id="new_password_first"
          className={"form-control" + (errors.newPasswordFirst ? " is-invalid" : "")}
          required
        />
        {errors.newPasswordFirst && <div className="invalid-feedback d-block">{errors.newPasswordFirst.message}</div>}
      </div>
      <div className="mb-3">
        <label htmlFor="new_password_second" className="form-label">
          Confirmer le mot de passe
        </label>
        <input
          type="password"
          {...register("newPasswordSecond", defaultErrors.newPasswordSecond)}
          id="new_password_second"
          className={"form-control" + (errors.newPasswordSecond ? " is-invalid" : "")}
          required
        />
        {errors.newPasswordSecond && <div className="invalid-feedback d-block">{errors.newPasswordSecond.message}</div>}
      </div>

      <FormButtonLoading type="submit" isPending={changePassword.isPending} label="Changer le mot de passe" className="btn btn-primary" />
    </form>
  );
}

export default ChangePasswordForm;
