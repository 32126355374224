import axios from "axios";
import { getPostPutAxiosInstance, handleError } from "./utils";
import { SchoolNivelEnum } from "../../../utils";
import { Period } from "../../../types";

class ProgrammationAPI {
  async update(nivel: SchoolNivelEnum, period: Period, data: any) {
    try {
      const response = await getPostPutAxiosInstance().put("/programmation/update/" + nivel + "/" + period, data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async reset(nivel: SchoolNivelEnum) {
    try {
      const response = await getPostPutAxiosInstance().put("/programmation/reset/" + nivel);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }
}

export default ProgrammationAPI;
