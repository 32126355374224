import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangePasswordForm, GrantedAdherentForm, GrantedVipForm, RituelParametersForm } from "../../components";
import { useDocumentTitle } from "../../hooks";
import { faCalendarDays, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { ModalTypeEnum } from "../../utils";
import { useModals } from "../../contexts/modals";

function ProfilParameters() {
  useDocumentTitle("Profile - M@ths en-vie");
  const { openModal } = useModals();

  return (
    <div>
      <RituelParametersForm />
      <hr className="border-3" />
      <button className="btn btn-primary" onClick={() => openModal(ModalTypeEnum.Programmation)}>
        <FontAwesomeIcon icon={faCalendarDays} /> Programmation
      </button>
      <hr className="border-3" />
      <ChangePasswordForm />
      <hr className="border-3" />
      <GrantedAdherentForm />
      <GrantedVipForm />
      <hr className="border-3" />
      <button className="btn btn-danger" onClick={() => openModal(ModalTypeEnum.DeleteAccount)}>
        <FontAwesomeIcon icon={faTrashCan} /> Supprimer mon compte
      </button>
    </div>
  );
}

export default ProfilParameters;
