import { useMutation } from "@tanstack/react-query";
import { api, ApiError } from "../../hooks";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ModalPropsDefault, MutationData } from "../../types";
import { Alert, CloseButton, Modal } from "react-bootstrap";

type FormValues = {
  password: string;
};

const defaultValues: FormValues = {
  password: "",
};

function DeleteAccountModal({ show, closeModal }: Readonly<ModalPropsDefault>) {
  const handleClose = () => {
    setTimeout(() => {
      deleteAccount.reset();
    }, 200);

    closeModal();
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  const deleteAccount = useMutation<MutationData, ApiError, FormValues>({
    mutationFn: (data) => {
      return api.profil.deleteAccount(data);
    },
    onSuccess: () => {
      reset();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    },
  });

  const onSubmit = (data: FormValues) => {
    if (window.confirm("Êtez vous sûr de vouloir supprimer définitevement votre compte")) {
      deleteAccount.mutate(data);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>
        <h4>Supprimer mon compte</h4>
        <CloseButton variant="white" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <h5>Veuillez saisir votre mot de passe pour confirmation.</h5>
        {deleteAccount.isError && <Alert variant={deleteAccount.error.variant}>{deleteAccount.error.message}</Alert>}
        {deleteAccount.isSuccess && <Alert variant="success">{deleteAccount.data.message}</Alert>}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="mb-3 input-group">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faLock} />
            </span>
            <div className="form-floating">
              <input
                type="password"
                {...register("password", {
                  required: { value: true, message: "Veuillez saisir votre mot de passe." },
                })}
                id="inputPassword"
                className={"form-control" + (errors.password ? " is-invalid" : "")}
                placeholder="Mot de passe"
                required
              />
              <label htmlFor="inputPassword" className="form-label">
                Mot de passe
              </label>
            </div>
            {errors.password && <div className="invalid-feedback d-block">{errors.password.message}</div>}
          </div>
          <p className="mt-2">
            * Attention <span className="fw-bold">toutes</span> vos données seront supprimées : problèmes, commentaires, mentions "j'aime", adresse mail...
          </p>
          <button type="submit" id="submitFormModal" className="d-none"></button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="btn btn-ternary">
          Annuler
        </button>
        <label htmlFor="submitFormModal" className={"btn btn-danger" + (deleteAccount.isPending ? " disabled" : "")}>
          Supprimer * {deleteAccount.isPending && <FontAwesomeIcon className="ms-1" icon={faSpinner} spin />}
        </label>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteAccountModal;
