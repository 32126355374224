import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@tanstack/react-query";
import { api, type ApiError, useDocumentTitle } from "../hooks";
import { useForm } from "react-hook-form";
import { FormButtonLoading } from "../components";
import { createSearchParams, useNavigate } from "react-router-dom";
import Routes from "../routes";
import type { MutationData } from "../types";
import { Alert } from "react-bootstrap";

type FormValues = {
  email: string;
};

const defaultValues: FormValues = {
  email: "",
};

function ResendVerifyEmail() {
  useDocumentTitle();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  const navigate = useNavigate();

  const resendVerify = useMutation<MutationData, ApiError, FormValues>({
    mutationFn: (data) => {
      return api.user.resendVerifyEmail(data);
    },

    onSuccess: (data) => {
      reset();
      navigate({ pathname: Routes.LOGIN, search: `?${createSearchParams({ message: data.message, variant: "success" })}` }, { replace: true });
    },
  });

  const onSubmit = (data: FormValues) => {
    resendVerify.mutate(data);
  };

  return (
    <div className="form-template mx-auto">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="label-form">
          <h1 className="h3 text-center">Renvoyer l'email de confirmation</h1>
        </div>
        <div className="alert-form">{resendVerify.isError && <Alert variant={resendVerify.error.variant}>{resendVerify.error.message}</Alert>}</div>

        <div className="body-form">
          <p className="mb-3">Veuillez renseigner votre email</p>

          <div className="mb-3 input-group">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
            <div className="form-floating">
              <input type="text" {...register("email", { required: true })} id="inputEmail" className={"form-control" + (errors.email ? " is-invalid" : "")} placeholder="Email" required autoFocus />
              <label htmlFor="inputEmail" className="form-label">
                Email
              </label>
            </div>
            {errors.email && <div className="invalid-feedback d-block">Vous devez saisir votre email</div>}
          </div>

          <div className="btn-form">
            <FormButtonLoading isPending={resendVerify.isPending} label="Envoyer" className="btn btn-outline-dark" />
          </div>
        </div>
      </form>
    </div>
  );
}

export default ResendVerifyEmail;
