import axios from "axios";
import { getGetAxiosInstance, getPostPutAxiosInstance, handleError } from "./utils";

class UserAPI {
  async getUserData() {
    try {
      const response = await getGetAxiosInstance().get("/user");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async login(data: any) {
    try {
      const response = await getPostPutAxiosInstance().put("/login", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async register(data: any) {
    try {
      const response = await getPostPutAxiosInstance().post("/register", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async resendVerifyEmail(data: any) {
    try {
      const response = await getPostPutAxiosInstance().post("/resend-verify-email", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async passwordResetRequest(data: any) {
    try {
      const response = await getPostPutAxiosInstance().post("/password-reset-request", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async passwordReset(data: any) {
    try {
      const response = await getPostPutAxiosInstance().post("/password-reset", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async contact(data: any) {
    try {
      const response = await getPostPutAxiosInstance().post("/contact", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async unsubscribeEmails(id: number, token: string, reSubscribe: boolean = false) {
    try {
      const response = await getPostPutAxiosInstance().put("/unsubscribe-emails", { id: id, token: token, reSubscribe: reSubscribe });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  logout() {
    return getPostPutAxiosInstance().put("/logout");
  }
}

export default UserAPI;
