import { FieldError, FieldErrorsImpl, FieldValues, Merge, Path, RegisterOptions, UseFormRegister } from "react-hook-form";

type CheckboxProps<T extends FieldValues, K extends Path<T>> = {
  name: K;
  register: UseFormRegister<T>;
  value: T[K];
  label: string;
  error?: FieldError;
  defaultErrors?: RegisterOptions<T, K>;
};

function Checkbox<T extends FieldValues, K extends Path<T>>({ name, register, value, label, error, defaultErrors }: Readonly<CheckboxProps<T, K>>) {
  return (
    <div className="form-check form-check-inline">
      <input type="radio" id={"problem_" + name + "_" + value} {...register(name, defaultErrors)} className={"form-check-input" + (error ? " is-invalid" : "")} value={value} />
      <label className="form-check-label" htmlFor={"problem_" + name + "_" + value}>
        {label}
      </label>
    </div>
  );
}

type CheckboxArrayProps<T extends FieldValues, K extends Path<T>> = {
  name: K;
  register: UseFormRegister<T>;
  value: T[K][number];
  label: string;
  error?: Merge<FieldError, FieldErrorsImpl<T[K]>>;
  defaultErrors?: RegisterOptions<T, K>;
  className?: string;
};

function CheckboxArray<T extends FieldValues, K extends Path<T>>({ name, register, value, label, error, defaultErrors, className = "" }: Readonly<CheckboxArrayProps<T, K>>) {
  return (
    <div className="form-check">
      <input type="checkbox" id={"filter_" + name + "_" + value} {...register(name, defaultErrors)} className={"form-check-input " + className + (error ? " is-invalid" : "")} value={value} />
      <label className="form-check-label" htmlFor={"filter_" + name + "_" + value}>
        {label}
      </label>
    </div>
  );
}

export { Checkbox, CheckboxArray };
