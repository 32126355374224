import { useDocumentTitle } from "../hooks";

function Credits() {
  useDocumentTitle("Crédits | Appenvie");

  return (
    <div className="my-0 mx-auto" style={{ maxWidth: 960 }}>
      <h1 className="text-center">Crédits</h1>

      <h2 className="mt-5">Remerciements</h2>

      <h2 className="mt-5">Technologies utilisées</h2>
      <p className="mt-3">
        Icones :{" "}
        <a href="https://fontawesome.com/" className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover">
          FontAwesome
        </a>
      </p>
      <p className="mt-2">
        Frontend :{" "}
        <a href="https://react.dev/" className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover">
          React
        </a>{" "}
        -{" "}
        <a href="https://getbootstrap.com/" className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover">
          Bootstrap
        </a>
      </p>
      <p className="mt-2">
        Backend :{" "}
        <a href="https://symfony.com/" className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover">
          Symfony
        </a>
      </p>
      <p className="mt-2">
        IA :{" "}
        <a href="https://platform.openai.com/docs/models/gpt-4o-mini" className="link-primary link-underline-opacity-0 link-underline-opacity-100-hover">
          Chat GPT (gpt-4o-mini)
        </a>
      </p>
    </div>
  );
}

export default Credits;
