import { useAuth } from "../contexts/auth";
import { LinearProgress } from "@mui/material";

export default function FakeProtectedRoute({ children }: React.PropsWithChildren) {
  const { isInitialLoading } = useAuth();

  const canConnect: boolean = !isInitialLoading;

  return canConnect ? children : <LinearProgress className="m-auto w-100" style={{ maxWidth: 960 }} />;
}
