import { faGear, faHouse, faRightFromBracket, faSpinner, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink } from "react-router-dom";
import Routes from "../routes";
import { useAuth } from "../contexts/auth";

type HeaderProps = {
  height: number;
};

function Header({ height }: Readonly<HeaderProps>) {
  return (
    <header className="bg-ternary fixed-top" style={{ height: height }}>
      <div id="navbar">
        <nav className="navbar navbar-expand navbar-dark bg-ternary d-flex justify-content-between">
          <div className="d-flex column-gap-4 justify-content-arround w-100" style={{ height: height }} id="navbarPrincipal">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <a href="https://www.mathsenvie.fr/" className="d-flex h-100 p-2">
                  <img className="align-self-center" src="img/logo_small.webp" alt="Site M@ths en-vie" style={{ maxHeight: 30 }} />
                </a>
              </li>
              <li className="ms-2 nav-item">
                <Link className="d-flex btn btn-ternary text-primary rounded-0 h-100" to={Routes.HOME}>
                  <FontAwesomeIcon className="bg-secondary p-2 rounded-5 align-self-center" icon={faHouse} />
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto">
              <UserHeader />
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

function UserHeader() {
  const { user, logout, isInitialLoading } = useAuth();

  return (
    <>
      {isInitialLoading && <FontAwesomeIcon icon={faSpinner} spin size="lg" className="text-white mx-3" style={{ marginTop: 15 }} />}
      {user && (
        <li className="nav-item dropdown">
          <button className="d-flex btn btn-ternary text-primary rounded-0 h-100" data-bs-toggle="dropdown" aria-expanded="false" aria-label="Profile">
            <FontAwesomeIcon className="bg-secondary p-2 rounded-5 align-self-center" icon={faUser} />
          </button>
          <ul className="dropdown-menu dropdown-menu-end bg-ternary no-dropdown-hover">
            <p className="p-1 mb-0 text-center">{user.username}</p>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <NavLink className="dropdown-item" to={Routes.PROFIL_PARAMETERS}>
                <FontAwesomeIcon icon={faGear} /> Paramètres
              </NavLink>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <button className="dropdown-item" id="logout" onClick={logout}>
                <FontAwesomeIcon icon={faRightFromBracket} /> Déconnexion
              </button>
            </li>
          </ul>
        </li>
      )}
      {!user && !isInitialLoading && (
        <>
          <li className="nav-item">
            <NavLink to={Routes.LOGIN} className="nav-link">
              Se connecter
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={Routes.REGISTER} className="nav-link">
              S'inscrire
            </NavLink>
          </li>
        </>
      )}
    </>
  );
}

export default Header;
