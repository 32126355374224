import { useForm } from "react-hook-form";
import { FormButtonLoading } from "../components";
import { api, ApiError, useDocumentTitle } from "../hooks";
import { useMutation } from "@tanstack/react-query";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import Routes from "../routes";
import { DefaultErrorsType, MutationData } from "../types";
import { Alert } from "react-bootstrap";

type FormValues = {
  newPasswordFirst: string;
  newPasswordSecond: string;
};

const defaultValues: FormValues = {
  newPasswordFirst: "",
  newPasswordSecond: "",
};

const defaultErrors: DefaultErrorsType<FormValues> = {
  newPasswordFirst: {
    required: "Veuillez saisir votre nouveau mot de passe.",
    minLength: { value: 6, message: "Le mot de passe doit contenir au moins 6 caractères." },
  },
  newPasswordSecond: {
    required: "Veuillez confirmer votre nouveau mot de passe.",
    validate: (value, formValues) => value === formValues.newPasswordFirst || "Les deux mots de passe doivent être identiques.",
  },
};

type ParamsType = {
  token: string;
};

function PasswordReset() {
  const { token } = useParams<ParamsType>() as ParamsType;
  const navigate = useNavigate();
  useDocumentTitle();

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { ...defaultValues, token: token } });

  const passwordReset = useMutation<MutationData, ApiError<FormValues>, FormValues>({
    mutationFn: (data) => {
      return api.user.passwordReset(data);
    },
    onSuccess: (data) => {
      reset();
      navigate({ pathname: Routes.LOGIN, search: `?${createSearchParams({ message: data.message, variant: "success" })}` }, { replace: true });
    },
    onError: (data) => {
      data.formErrors.forEach(({ name, message }) => {
        setError(name, { message: message });
      });
    },
  });

  const onSubmit = (data: FormValues) => {
    passwordReset.mutate(data);
  };

  return (
    <div className="form-template mx-auto">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="label-form">
          <h1 className="h3 text-center">Réinitialiser son mot de passe</h1>
        </div>
        <div className="alert-form">
          {passwordReset.isError && <Alert variant={passwordReset.error.variant}>{passwordReset.error.message}</Alert>}

          {errors.root && <Alert variant="danger">{errors.root.message}</Alert>}
        </div>

        <div className="body-form">
          <div className="mb-3">
            <label htmlFor="new_password_first" className="form-label">
              Nouveau mot de passe
            </label>
            <input
              type="password"
              {...register("newPasswordFirst", defaultErrors.newPasswordFirst)}
              id="new_password_first"
              className={"form-control" + (errors.newPasswordFirst ? " is-invalid" : "")}
              autoFocus
              required
            />
            {errors.newPasswordFirst && <div className="invalid-feedback d-block">{errors.newPasswordFirst.message}</div>}
          </div>
          <div className="mb-3">
            <label htmlFor="new_password_second" className="form-label">
              Confirmer le mot de passe
            </label>
            <input
              type="password"
              {...register("newPasswordSecond", defaultErrors.newPasswordSecond)}
              id="new_password_second"
              className={"form-control" + (errors.newPasswordSecond ? " is-invalid" : "")}
              required
            />
            {errors.newPasswordSecond && <div className="invalid-feedback d-block">{errors.newPasswordSecond.message}</div>}
          </div>

          <input type="hidden" {...register("token")} />

          <div className="btn-form">
            <FormButtonLoading type="submit" isPending={passwordReset.isPending} label="Changer le mot de passe" className="btn btn-outline-dark" />
          </div>
        </div>
      </form>
    </div>
  );
}

export default PasswordReset;
