import axios from "axios";
import { getGetAxiosInstance, handleError } from "./utils";
import { SchoolZoneEnum } from "../../../utils";

class RituelAPI {
  async get(schoolZone: SchoolZoneEnum, classNivel: string[]) {
    try {
      const response = await getGetAxiosInstance().get("/rituel/" + schoolZone + "/" + classNivel.join(";"));
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async refetch(schoolZone: SchoolZoneEnum, nivel: string) {
    try {
      const response = await getGetAxiosInstance().get("/rituel/refetch/" + schoolZone + "/" + nivel);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }
}

export default RituelAPI;
