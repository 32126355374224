import { Link } from "react-router-dom";
import Routes from "../routes";

function Footer() {
  return (
    <footer style={{ backgroundColor: "#F0F0F0" }}>
      <div className="d-flex flex-md-row flex-column justify-content-around mx-auto py-4 row-gap-4" style={{ maxWidth: 960 }}>
        <div className="text-center">
          <p className="fs-5 mb-2 fw-medium text-uppercase">Une production</p>
          <div>
            <a href="https://www.mathsenvie.fr/association" target="_blank" rel="noreferrer" className="logo-assoc">
              <img src="img/logoAsso.webp" alt="Association Logo" width="92.5" height="92.5" />
            </a>
          </div>
        </div>
        <div className="text-center">
          <p className="fs-5 mb-2 fw-medium text-uppercase">Soutenu par</p>
          <div>
            <a href="https://dane.web.ac-grenoble.fr/" rel="noreferrer" target="_blank">
              <img src="img/logoDane.webp" alt="Logo dane Grenoble" width="250" height="92.5" />
            </a>
          </div>
        </div>
        <div className="text-center">
          <p className="fs-5 mb-2 fw-medium text-uppercase">Notre partenaire</p>
          <div>
            <a href="https://www.generation5.fr/" rel="noreferrer" target="_blank">
              <img src="img/logoGeneration5.webp" alt="Logo génération 5" width="250" height="92.5" />
            </a>
          </div>
        </div>
      </div>
      <div className="bg-black text-center text-white" style={{ padding: "2rem" }}>
        <div className="text-uppercase d-inline-block" style={{ letterSpacing: 2, fontSize: "0.9rem", maxWidth: 960 }}>
          Les applications M@ths en-vie - Réalisation : M@ths'n Co / Rémi GILGER
        </div>
        <div className="d-flex flex-wrap column-gap-3 justify-content-center mt-2">
          <Link className="link-secondary link-opacity-75-hover link-underline-opacity-0" to={Routes.LEGAL_NOTICES}>
            Mentions légales
          </Link>
          <p> - </p>
          <Link className="link-secondary link-opacity-75-hover link-underline-opacity-0" to={Routes.PRIVACY}>
            Politique de confidentialité
          </Link>
          <p> - </p>
          <Link className="link-secondary link-opacity-75-hover link-underline-opacity-0" to={Routes.CGU}>
            Conditions Générales d'Utilisation
          </Link>
          <p> - </p>
          <Link className="link-secondary link-opacity-75-hover link-underline-opacity-0" to={Routes.CONTACT}>
            Nous contacter
          </Link>
          <p> - </p>
          <Link className="link-secondary link-opacity-75-hover link-underline-opacity-0" to={Routes.CREDITS}>
            Crédits
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
