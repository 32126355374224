import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ModalTypeEnum } from "../utils";
import { ModalPropsDefault } from "../types";
import { useLocation } from "react-router-dom";
import { DeleteAccountModal, EditProgrammationModal, MessageModal, ProgrmmationModal, RituelParametersModal } from "../components/modals";

const modalsElements = {
  [ModalTypeEnum.DeleteAccount]: DeleteAccountModal,
  [ModalTypeEnum.EditProgrammation]: EditProgrammationModal,
  [ModalTypeEnum.Message]: MessageModal,
  [ModalTypeEnum.Programmation]: ProgrmmationModal,
  [ModalTypeEnum.RituelParameters]: RituelParametersModal,
} as const;

type GetRequiredKeys<T> = { [K in keyof T as undefined extends T[K] ? never : K]: T[K] };
type MakeOptionalIfEmpty<T> = keyof T extends never ? [] : keyof GetRequiredKeys<T> extends never ? [T?] : [T];

type ModalsPropsAdditional<T extends keyof typeof modalsElements> = Omit<React.ComponentProps<(typeof modalsElements)[T]>, keyof ModalPropsDefault>;

type OpenModal = <T extends keyof typeof modalsElements>(modal: T, ...[props]: MakeOptionalIfEmpty<ModalsPropsAdditional<T>>) => void;
type CloseModal = (modal: ModalTypeEnum) => void;
type OnModalClose = (modal: ModalTypeEnum | ModalTypeEnum[], callback: () => void) => void;
type IsModalOpen = (modal: ModalTypeEnum) => boolean;
type SaveData = (modal: ModalTypeEnum, data: any) => void;

type ContextData = {
  openModal: OpenModal;
  closeModal: CloseModal;
  onModalClose: OnModalClose;
  isModalOpen: IsModalOpen;
};
const ModalsContext = createContext<ContextData | undefined>(undefined);

type ModalsState = {
  [modal: string]: {
    _show: boolean;
    show: boolean;
    savedData: any;
    props: any;
    onClose?: () => void;
  };
};

const ModalsProvider = ({ children }: React.PropsWithChildren) => {
  const [modals, setModals] = useState<ModalsState>(
    Object.values(ModalTypeEnum).reduce((acc, modal) => ({ ...acc, [modal]: { _show: false, show: false, savedData: null, props: null, onClose: undefined } }), {})
  );

  const openModal: OpenModal = useCallback((modal, ...props) => {
    setModals((m) => ({
      ...m,
      [modal]: { ...m[modal], _show: true, show: true, props: props[0] ?? null },
    }));
  }, []);

  const isModalOpen: IsModalOpen = useCallback((modal) => modals[modal].show, [modals]);

  const closeModal: CloseModal = useCallback((modal) => {
    setModals((m) => {
      let callback = m[modal].onClose;
      if (callback && m[modal].show) {
        callback();
      }

      return {
        ...m,
        [modal]: {
          ...m[modal],
          show: false,
          onClose: undefined,
        },
      };
    });
    setTimeout(() => {
      setModals((m) => ({
        ...m,
        [modal]: {
          ...m[modal],
          props: null,
          _show: false,
        },
      }));
    }, 200);
  }, []);

  const onModalClose: OnModalClose = useCallback((modals, callback) => {
    if (Array.isArray(modals)) {
      modals.forEach((modal) => {
        setModals((m) => ({
          ...m,
          [modal]: {
            ...m[modal],
            onClose: callback,
          },
        }));
      });
    } else {
      setModals((m) => ({
        ...m,
        [modals]: {
          ...m[modals],
          onClose: callback,
        },
      }));
    }
  }, []);

  const saveData: SaveData = (modal, dataToSave) => {
    setModals((m) => ({
      ...m,
      [modal]: { ...m[modal], savedData: dataToSave },
    }));
  };

  const modalsTemplate = Object.entries(modalsElements).map((element) => {
    const name = element[0] as ModalTypeEnum;
    const Component = element[1];

    if (modals[name]._show) {
      return (
        <Component key={name} closeModal={() => closeModal(name)} show={modals[name].show} saveData={(data: any) => saveData(name, data)} savedData={modals[name].savedData} {...modals[name].props} />
      );
    }

    return null;
  });

  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      Object.keys(modals).forEach(function (modal) {
        if (isModalOpen(modal as ModalTypeEnum)) {
          closeModal(modal as ModalTypeEnum);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const value = useMemo(() => ({ openModal, closeModal, onModalClose, isModalOpen }), [closeModal, isModalOpen, onModalClose, openModal]);

  return (
    <ModalsContext.Provider value={value}>
      {modalsTemplate}
      {children}
    </ModalsContext.Provider>
  );
};

const useModals = () => {
  const object = useContext(ModalsContext);
  if (!object) {
    throw new Error("useModals must be used within a Provider");
  }

  return object;
};

export { ModalsProvider, useModals };
