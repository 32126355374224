import { useForm } from "react-hook-form";
import { FormButtonLoading } from "../components";
import { api, ApiError } from "../hooks";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../contexts/auth";
import { MutationData } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip, Alert } from "react-bootstrap";

type FormValues = {
  codeAdherent: string;
};

const defaultValues: FormValues = {
  codeAdherent: "",
};

function GrantedAdherentForm() {
  const { user } = useAuth();
  const { register, handleSubmit, reset } = useForm({ defaultValues: defaultValues });

  const grantedAdherent = useMutation<MutationData, ApiError, FormValues>({
    mutationFn: (data) => {
      return api.profil.grantedAdherent(data);
    },
    onSuccess: () => {
      reset();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    },
  });

  const onSubmit = (data: FormValues) => {
    grantedAdherent.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {grantedAdherent.isError && <Alert variant={grantedAdherent.error.variant}>{grantedAdherent.error.message}</Alert>}
      {grantedAdherent.isSuccess && <Alert variant="success">{grantedAdherent.data.message}</Alert>}
      <div className="input-group mb-3">
        <input type="text" {...register("codeAdherent")} aria-describedby="becomeAdherent" className="form-control border-end-0" placeholder="Code adhérent" required />
        <OverlayTrigger
          overlay={
            <Tooltip id="edition-selection">Entrez ici le code que vous avez reçu dans le courriel de HelloAsso vous confirmant votre adhésion et vous souhaitant la bienvenue dans M@ths'n Co</Tooltip>
          }
        >
          <span className="input-group-text bg-white border-start-0">
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
        </OverlayTrigger>
        <FormButtonLoading
          className={"btn btn-outline-ternary w-50" + (user?.isAdherent ? " bg-secondary disabled" : "")}
          type="submit"
          label={user?.isAdherent ? "Vous êtes déjà identifié comme adhérent" : "Envoyer"}
          id="becomeAdherent"
          isPending={grantedAdherent.isPending}
        />
      </div>
    </form>
  );
}

export default GrantedAdherentForm;
