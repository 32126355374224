enum SchoolZoneEnum {
    ZoneA = "zoneA",
    ZoneB = "zoneB",
    ZoneC = "zoneC",
    Guadeloupe = "guadeloupe",
    Martinique = "martinique",
    Guyane = "guyane",
    Réunion = "reunion",
    Mayotte = "mayotte",
    Corse = "corse",
    SaintPierreEtMiquelon = "pierreEtMiquelon",
    Polynésie = "polynesie",
}

export default SchoolZoneEnum;
